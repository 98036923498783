var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-card-title',{staticClass:"mt-0 pt-0"},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.order_payments.length)+") Payments")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('h3',[_vm._v("Total Paid: "+_vm._s(_vm.total_paid)+" | Total Owing: "+_vm._s(_vm.total_owing))]),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.order_payments,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.payment_means",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.payment_means))]),_c('small',[_vm._v("Made By: "+_vm._s(item.paid_by)+" ")])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" F CFA ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fee)+" F CFA ")]}},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor[item.confirmed]},on:{"click":[function($event){$event.stopPropagation();_vm.setDialog=true},function($event){return _vm.changeSwitch(item.id)}]}},[(item.confirmed)?_c('span',[_vm._v("Validated")]):_c('span',[_vm._v("Pending")])])]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.setDialog),callback:function ($$v) {_vm.setDialog=$$v},expression:"setDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"black--text"},[_vm._v("Change Payment State")]),_c('v-card-text',{staticClass:"black--text pt-2"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.transactionDetails)}}),_c('v-switch',{staticClass:"my-1 py-0",attrs:{"label":"Validated","color":"success","inset":""},on:{"click":function($event){return _vm.changeValidateState()}},model:{value:(_vm.validatedState),callback:function ($$v) {_vm.validatedState=$$v},expression:"validatedState"}}),_c('v-switch',{staticClass:"my-1 py-0",attrs:{"label":"Pending","color":"primary","inset":""},on:{"click":function($event){return _vm.changePendingState()}},model:{value:(_vm.pendingState),callback:function ($$v) {_vm.pendingState=$$v},expression:"pendingState"}}),_c('v-divider'),_c('v-switch',{staticClass:"my-1 py-0",attrs:{"label":"Confirm Payment","color":"primary","inset":""},on:{"click":function($event){return _vm.changePaymentState()}},model:{value:(_vm.payment_confirmed),callback:function ($$v) {_vm.payment_confirmed=$$v},expression:"payment_confirmed"}}),_c('v-switch',{staticClass:"my-1 py-0",attrs:{"label":"Cancel Payment","color":"primary","inset":""},on:{"click":function($event){return _vm.changeCancelledState()}},model:{value:(_vm.cancelled),callback:function ($$v) {_vm.cancelled=$$v},expression:"cancelled"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeTransactionState()}}},[_vm._v(" Change ")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }