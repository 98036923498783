<template>
  <v-card >
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <order-detail></order-detail>
      </v-tab-item>

      <v-tab-item>
        <make-order-payment></make-order-payment>
      </v-tab-item>

      <v-tab-item>
        <order-payments></order-payments>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiCashRegister, mdiInformationOutline, mdiHistory } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import OrderDetail from './OrderDetail.vue'
import OrderPayments from './OrderPayments.vue'
import MakeOrderPayment from './MakeOrderPayment'
import axios from 'axios'


export default {
  components: {
    OrderDetail,
    OrderPayments,
    MakeOrderPayment,
  },
  data() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Order Details', icon: mdiInformationOutline },
      { title: 'Make Payment', icon: mdiCashRegister },
      { title: 'Payments History', icon: mdiHistory },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiInformationOutline,
        mdiCashRegister,
        mdiHistory,
      },
    }
  },
  
  mounted(){
    document.title = "Manager: Market Place | Order"
  },

}
</script>
