<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Make a Payment</p>
        <v-row>
          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="amount"
              outlined dense ref="amount"
              label="Amount Paid"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="fee"
              outlined dense ref="fee"
              label="Payment Fee"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedPaymentOperator"
              ref="selectedPaymentOperator"
              :items="PaymentOperators"
              item-text="name"
              item-value="id"
              outlined dense
              label="Select Payment Operator"
            ></v-select>
          </v-col>

          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <!-- current password -->
            <v-text-field
              v-model="currentPassword"
              ref="currentPassword"
              :type="isCurrentPasswordVisible ? 'text' : 'password'"
              :append-icon="isCurrentPasswordVisible ? mdiEyeOffOutline:mdiEyeOutline"
              label="Input Current Password"
              outlined
              dense
              @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addOrderPayment()"
        >
          Make Payment
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      amount: '',
      fee: '',
      PaymentOperators: [],
      selectedPaymentOperator: '',
      loading: false,
      isCurrentPasswordVisible: false,
      payment_ref: "",
      currentPassword: '',

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },

  mounted(){
    this.getPaymentOperators()
    this.getPaymentRef()
    document.title = "PettyCash: Manager | Order Payment"
  },

  methods:{
    async getPaymentOperators(){
      this.loading = true

      await axios
        .get('/api/v1/manager/market_place/get/payment/methods/')
        .then(response => {
          this.PaymentOperators = response.data
        })
        .catch(error => {
          if (error.response){
            for (const property in error.response.data){
              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
          })
      this.loading = false
    },

    async getPaymentRef(){
        const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
        await axios
            .get('/api/v1/manager/market_place/create/order/payment_ref/'+requested_order_id)
            .then(response => {
              console.log(response.data)
                this.payment_ref = response.data
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

    async addOrderPayment(){
      if(this.amount === "" || String(Number(this.amount)) === "NaN"){
        if(this.amount === ""){
          this.$store.commit('setSnackBarMessage', "Please input the amount")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.amount.focus()  // this causes a an auto fucos to the element
        }
        else{
          this.$store.commit('setSnackBarMessage', "Please input only numbers for the amount")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.amount.focus()  // this causes a an auto fucos to the element
        }
      }
      else if(this.currentPassword === ""){
        this.$store.commit('setSnackBarMessage', "Please input ur password.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.currentPassword.focus()  // this causes a an auto fucos to the element
      }
      else if(this.selectedPaymentOperator === ""){
        this.$store.commit('setSnackBarMessage', "Please select payment operator.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.selectedPaymentOperator.focus()  // this causes a an auto fucos to the element
      }
      else{
        const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
        let formData = new FormData()
        formData.append('amount', this.amount)
        formData.append('fee', this.fee)
        formData.append('payment_ref', this.payment_ref)
        formData.append('payment_means', this.selectedPaymentOperator)
        formData.append('order_id', requested_order_id)
        formData.append('password', this.currentPassword)

        axios
          .post('/api/v1/manager/market_place/make/order/payment', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 

            if (response.data[0].category === "success"){
              var navigation = this.$router
              setTimeout(function(){
                navigation.go()
              }, 3000)
            }
          })
          .catch(error => {
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
      }
    },

  },
  

}
</script>

