<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card color="white" class="elevation-2">
      <v-card-text style="font-size: 12px">
          <h2 class="text--dark">Billing For Order: {{order_details.order_id}}</h2> 
          <v-row wrap>
              <v-col cols="12" xs="12" md="6" class="text-left mt-0">
                <b>Total Cost:</b> {{order_details.total_cost}} F CFA
              </v-col>
              <v-col cols="12" xs="12" md="6" class="text-right mt-0">
                  <b>Total Paid:</b> {{order_details.total_paid}} F CFA
              </v-col>

              <v-col cols="6" class="text-left mt-0 pt-0">
                  <b>Order Date:</b> {{order_details.get_date_str}}
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-if="order_details.status_code == 'Pending'">
                  <b>Order Status:</b> {{order_details.status_code}}
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-if="order_details.status_code == 'Payment Validated'">
                  <b>Order Status:</b> {{order_details.status_code}}
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-if="order_details.status_code == 'Interrupted'">
                  <b>Order Status:</b> {{order_details.status_code}}
              </v-col>

              <v-col cols="6" class="text-left mt-0 pt-0">
                  <b>Payment Channel:</b> {{order_details.payment_means}}
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-if="order_details.payment_confirmed">
                  <b>Payment Status:</b> Confirmed
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-else>
                  <b>Payment Status:</b> Pending
              </v-col>

              <v-col cols="6" class="text-left mt-0 pt-0">
                  <b>Shipping Location:</b> {{order_details.shipping_town}} - {{order_details.shipping_location}}
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-if="order_details.delivery_confirmed">
                  <b >Delivery Status:</b> Confirmed
              </v-col>
              <v-col cols="6" class="text-right mt-0 pt-0" v-else>
                  <b>Delivery Status:</b> Pending
              </v-col>
          </v-row>
      </v-card-text>
    </v-card>

    <v-divider></v-divider>
    <v-card color="white" class="elevation-2 mt-4">
      <v-card-text style="font-size: 12px">
          <h2 class="text--dark mb-2">Assigning Delivery</h2> 
          <v-row wrap>
            <v-col
              cols="12" md="6"
              class="py-0 my-2"
            >
              <v-select
                v-model="selectedDeliverer"
                ref="selectedDeliverer"
                :items="deliverers"
                item-text="username"
                item-value="id"
                outlined dense
                label="Assign Delivery Person"
              ></v-select>
          </v-col>
          <v-col
              cols="12" md="6"
            >
              <spinner v-if="loading" class="text-end"></spinner>
              <v-btn
                v-else
                color="primary"
                @click="updateDeliveryPerson()"
              >
                Update Delivery
              </v-btn>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>

    <v-divider></v-divider>
    <div class="mt-1" style="max-height: 400px; overflow: auto; "> 
        <h3 class=" elevation-2 pa-2 font-weight-bold text-left">({{order_items.length}}) Items Requested</h3>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="order_items"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.product`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.product }}</span>
              <small>Category: {{ item.category }}</small>
            </div>
          </template>

          <template #[`item.price`]="{item}">
            {{ item.price}} FCFA
          </template>

          <template #[`item.unit_price`]="{item}">
            {{ item.unit_price }} FCFA
          </template>

          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
    </div>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'

export default {
  components: {
    Spinner,
  },
  
  data() {
    return {
      headers: [
        { text: 'Item', value: 'product' },
        { text: 'Total Cost', value: 'price' },
        { text: 'Unit Cost', value: 'unit_price' },
        { text: 'Qty Demanded', value: 'quantity' },
        { text: 'No InStock', value: 'quantity_left' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      loading: false,
      search: '',
      payment_ref: '',
      requested_order: '',
      total_cost: '',
      selectedDeliverer: '',

      deliverers: [],
      order_details: [],
      order_items: [],
      order_payments: [],

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

  mounted(){
    this.getOrderDetail();
    this.getOrderItems();
    this.getOrderPayments();
    this.getOrderDeliverers()
    document.title = "PettyCash: Manager | Order Details"
  },

  methods:{
    async getOrderDetail(){
      this.loading = true
      
      const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
      await axios
        .get('/api/v1/manager/market_place/get/order/detail/'+requested_order_id+'/')
        .then(response => {
            this.order_details = response.data  // get the data and fill into requested_order
            this.payment_ref = response.data.payment_ref
            this.total_cost = this.order_details.total_cost
        })
        .catch(error => {
            if (error.response){
                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })
      this.loading = false
    },

    async getOrderPayments(){
      this.loading = true
      
      const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
      await axios
        .get('/api/v1/manager/market_place/get/order/payments/'+requested_order_id+'/')
        .then(response => {
            this.order_payments = response.data  // get the data and fill into requested_order
        })
        .catch(error => {
              if (error.response){
                  this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                  this.$store.commit('setSnackBarColor', "red darken-4")
                  this.$store.commit('activateSnackBar', true)
              }else{
                  this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                  this.$store.commit('setSnackBarColor', "red darken-4")
                  this.$store.commit('activateSnackBar', true)
              }
          })
      this.loading = false
    },

    async getOrderItems(){
      this.loading = true
      
      const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
      await axios
        .get('/api/v1/manager/market_place/get/order/items/'+requested_order_id+'/')
        .then(response => {
          this.order_items = response.data  // get the data and fill into requested_order
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

    async getOrderDeliverers(){
      this.loading = true
      
      await axios
        .get('/api/v1/manager/market_place/get/order/deliverers/')
        .then(response => {
          this.selectedDeliverer = this.order_details.deliverer_userprofile_id
          this.deliverers = response.data  // get the data and fill into requested_order
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

    async updateDeliveryPerson(){
        if(this.selectedDeliverer === ""){
            this.$store.commit('setSnackBarMessage', "Please select delivery person.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedDeliverer.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
  
            const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
            let formData = new FormData()
            formData.append('deliverer_id', this.selectedDeliverer)
            formData.append('order_id', requested_order_id)

            await axios
                .post('/api/v1/manager/market_place/orders/update/delivery', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].town === "success"){
                      var navigation = this.$router
                      setTimeout(function(){
                        navigation.go()
                      }, 3000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            
            this.loading = false
        }

    },
  },
}
</script>
