<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
  <v-card-text>
      <v-card-title class="mt-0 pt-0">
        <span class="me-3">({{ order_payments.length }}) Payments</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <h3>Total Paid: {{ total_paid }} | Total Owing: {{ total_owing }}</h3>
      <v-data-table
        :headers="headers"
        :items="order_payments"
        item-key="id"
        class="table-rounded"
        :items-per-page="10"
        disable-sort
        :search="search"
      >
        <!-- name -->
        <template #[`item.payment_means`]="{item}">
          <div class="d-flex flex-column" style="cursor: pointer">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.payment_means }}</span>
            <small>Made By: {{ item.paid_by }} </small>
          </div>
        </template>
        <template #[`item.amount`]="{item}">
          {{ item.amount }} F CFA
        </template>
        <template #[`item.fee`]="{item}">
          {{ item.fee }} F CFA
        </template>

        <template #[`item.confirmed`]="{item}">
          <v-chip 
            :color="statusColor[item.confirmed]"
            @click.stop="setDialog=true"
            @click="changeSwitch(item.id)"
            >
            <span v-if="item.confirmed">Validated</span>
            <span v-else>Pending</span>
          </v-chip>
        </template>

        <template #[`item.get_date_str`]="{item}">
          {{ item.get_date_str }}
        </template>
      </v-data-table>

      <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class="black--text">Change Payment State</v-card-title> 
          <v-card-text class="black--text pt-2">
            <span v-html="transactionDetails"></span>

            <v-switch class="my-1 py-0" label="Validated" color="success" inset v-model="validatedState" @click="changeValidateState()"></v-switch>
            <v-switch class="my-1 py-0" label="Pending" color="primary" inset v-model="pendingState" @click="changePendingState()"></v-switch>
            <v-divider></v-divider>
            <v-switch class="my-1 py-0" label="Confirm Payment" color="primary" inset v-model="payment_confirmed" @click="changePaymentState()"></v-switch>
            <v-switch class="my-1 py-0" label="Cancel Payment" color="primary" inset v-model="cancelled" @click="changeCancelledState()"></v-switch>
            <v-btn color="primary" @click="changeTransactionState()" > Change </v-btn> 
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
      </v-dialog> 
    </v-card-text>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      true: 'success',
      false: 'primary',
      /* eslint-enable key-spacing */
    }
    const order_id = this.$route.params.id
    return {
      setDialog: false,
      order_id,
      headers: [
        { text: 'Payment Means', value: 'payment_means' },
        { text: 'Amount', value: 'amount' },
        { text: 'Fees', value: 'fee' },
        { text: 'Status', value: 'confirmed' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      selectedPayment: '',
      validatedState: false,
      pendingState: false,
      payment_confirmed: false,
      cancelled: false,
      dialogTitle: '',
      transactionDetails: '',
      total_paid: '',
      total_owing: '',

      statusColor,
      search: '',
      loading: false,
      product_detail: '',
      order_payments: [],
    }
  },

  mounted(){
    this.getProductDetails()
    this.getOrderPayments()
    document.title = "PettyCash: Manager | Order Payments"
  },

  methods:{
    changeSwitch(payment_id){
      axios
        .get('/api/v1/manager/market_place/get/order/requested_payment/'+payment_id+'/')
        .then(response => {
          const payment_means = response.data[0]["payment_means"]
          const paid_by = response.data[0]["paid_by"]
          const amount = response.data[0]["amount"]
          const fee = response.data[0]["fee"]

          this.selectedPayment = payment_id
          this.transactionDetails = "Change the state of this order payment of amount <b>'"+amount+"' F CFA</b> with fee <b>'"+fee+"' F CFA</b> initiated by <b>'"+paid_by+"'</b> through <b>'"+payment_means+"'</b>"

          this.pendingState = response.data[0]["pending_state"]
          this.validatedState = response.data[0]["validated_state"]
          this.payment_confirmed = response.data[0]["payment_confirmed"]
          this.cancelled = response.data[0]["cancelled"]
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeValidateState(){
      if(this.validatedState){
        this.pendingState = false
      }
    },
    
    changePendingState(){
      if(this.pendingState){
        this.validatedState = false
      }
    },
    
    changeCancelledState(){
      if(this.cancelled){
        this.payment_confirmed = false
      }
    },
    
    changePaymentState(){
      if(this.payment_confirmed){
        this.cancelled = false
      }
    },

    changeTransactionState(){
      if (!this.pendingState && !this.validatedState){
        this.$store.commit('setSnackBarMessage', "Error: Please select a state")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after           
      }
      else{
        let formData = new FormData()
        formData.append('pendingState', this.pendingState)
        formData.append('validatedState', this.validatedState)
        formData.append('payment_confirmed', this.payment_confirmed)
        formData.append('cancelled', this.cancelled)

        axios
          .post('/api/v1/manager/market_place/change/order/requested_payment/'+this.selectedPayment+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            this.getOrderPayments()
            this.setDialog = false
          })
          .catch(error => {
            this.setDialog = false
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
      }
    },

    async getProductDetails(){
      this.loading = true
      const product_id = this.$route.params.id

      await axios
        .get('/api/v1/manager/market_place/get/product/detail/'+product_id+'/')
        .then(response => {
          console.log(response.data)

          this.product_detail = response.data["product_info"]
          this.total_paid = product_detail.total_paid
          this.total_owing = product_detail.total_owing
        })
        .catch(error => {
            if (error.response){
                for (const property in error.response.data){
                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured please try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })

      this.loading = false
    },

    async getOrderPayments(){
      this.loading = true
      
      const requested_order_id = this.$route.params.id  // get requested_order_id from url and send request to server
      await axios
        .get('/api/v1/manager/market_place/get/order/payments/'+requested_order_id+'/')
        .then(response => {
          console.log(response.data)
            this.order_payments = response.data
        })
        .catch(error => {
              if (error.response){
                  this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                  this.$store.commit('setSnackBarColor', "red darken-4")
                  this.$store.commit('activateSnackBar', true)
              }else{
                  this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                  this.$store.commit('setSnackBarColor', "red darken-4")
                  this.$store.commit('activateSnackBar', true)
              }
          })
      this.loading = false
    },
  },

}
</script>
